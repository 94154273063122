<template>
  <div class="video-player" style="margin: 0 auto; position: relative">
    <video
      :id="videoId"
      class="video-js vjs-default-skin"
      controls
      preload="auto"
      playsinline
      webkit-playsinline></video>
  </div>
</template>

<script>
// lib
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import "./custom-theme.css";

export default {
  name: 'video-player',
  props: {
    options: {
      type: Object,
      required: true,
    },
    videoId: {
      type: String,
      default: 'player-container-id'
    }
  },
  data() {
    return {
      player: null,
      fileID: null,
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.player) {
      if (this.fileID) {
        this.player.dispose();
      } else {
        this.player.dispose();
      }
      this.player = null;
      this.fileID = null;
    }
  },
  methods: {
    initialize() {
      // 检查并销毁已有播放器实例
      if (this.player) {
        this.player.dispose();
        this.player = null;
      }

      const source = this.options.sources[0];
      console.log(source)
      const fileID = source.fileID;

      if (fileID) {
        // 使用 TcPlayer 进行播放
        const psign = this.psignCreate(fileID);
        const player = TCPlayer(this.videoId, {
          appID: '1309121823',
          fileID: fileID,
          psign: psign,
          licenseUrl: 'license/url',
        });
        this.player = player;
        this.fileID = fileID;
      } else {
        // 使用 Video.js 播放本地视频
        const player = videojs(this.videoId, {
          sources: [
            {
              src: source.src, // 本地视频地址
              type: source.type || 'video/mp4', // 默认类型为 mp4
            },
          ],
        });
        this.player = player;
        this.fileID = null;
      }
    },
    psignCreate(fileID) {
      let jwt = require('jsonwebtoken');
      let appId = 1309121823; // 用户 appid
      let currentTime = Math.floor(Date.now() / 1000);
      let psignExpire = currentTime + 3600 * 24; // 可任意设置过期时间，示例1h
      let key = 'gfLft0WUOgOte3SvvjPR';
      let payload = {
        appId: appId,
        fileId: fileID,
        contentInfo: {
          audioVideoType: 'ProtectedAdaptive',
          drmAdaptiveInfo: {
            privateEncryptionDefinition: 1427930,
          },
        },
        currentTimeStamp: currentTime,
        drmLicenseInfo: {
          expireTimeStamp: psignExpire,
        },
      };
      let token = jwt.sign(payload, key);
      return token;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(options) {
        if (options && options.sources && options.sources.length) {
          this.initialize();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
